<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs-items v-model="selectedAppBarTab">
          <v-tab-item
            :key="tab.key"
            :value="tab.key"
            v-for="tab in tabs"
          >
            <BookingList
              v-if="tab.key === 'bookings'"
              :customer="uuid"
              :step="50"
              :length="50"
            />
            <OrderList v-if="tab.key === 'orders'" />
            <SubscriptionList v-if="tab.key === 'subscriptions'" />
            <PaymentList v-if="tab.key === 'payments'" />
            <InvoiceList v-if="tab.key === 'invoices'" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import BookingList from "@/components/BookingList"
  import OrderList from "@/components/OrderList"
  import SubscriptionList from "@/components/SubscriptionList"
  import PaymentList from "@/components/PaymentList"
  import InvoiceList from "@/components/InvoiceList"

  export default {
    name: 'Customer',
    components: {
      SubscriptionList,
      OrderList,
      BookingList,
      PaymentList,
      InvoiceList
    },
    data() { 
      return {
        customer: null,
        uuid: null,
        payments: null,
        order: null,
        invoices: null,
        tabs: [
          {
            title: this.$t('timeline'),
            key: 'timeline',
            badge: {
              color: 'green',
              content: 0,
            }
          },
          {
            title: this.$t('orders'),
            key: 'orders',
            badge: {
              color: 'green',
              content: 0,
            }
          },
          {
            title: this.$t('invoices'),
            key: 'invoices',
            badge: {
              color: 'green',
              content: 0,
            }
          },{
            title: this.$t('payments'),
            key: 'payments',
            badge: {
              color: 'green',
              content: 0,
            }
          },
          {
            title: this.$t('bookings'),
            key: 'bookings',
            badge: {
              color: 'green',
              content: 0,
            }
          },
          {
            title: this.$t('subscriptions'),
            key: 'subscriptions',
            badge: {
              color: 'green',
              content: 0,
            }
          }
        ]
      }
    },
    methods: {
      getCustomerData() {
        this.$store.dispatch('getCustomerData', this.customer).then(customerData => {
          this.payments = customerData.payments
          this.orders = customerData.orders
          this.invoices = customerData.invoices
          this.bookings = customerData.bookings
          this.subscriptions = customerData.subscriptions
        })
      },
    },
    computed: {
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
      customers() {
        return this.$store.state.customers
      }
    },
    created() {
      this.$store.dispatch('getCustomers', {})
    },
    mounted: function() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.customer = this.$store.getters.customer(this.$route.params.uuid)
      this.getCustomerData()

      if(this.customer) {
        this.$route.meta.appBarTitle = this.customer.address.name_line
      }
    },
    destroyed() {
      this.$store.commit('updateAppBarTabs', [])
    }
  }
</script>
